import React, { useState } from "react";
import Select from "react-select";
import axios from "axios";
import useFetch from "../../../useFetch";

import { api_path } from "../../../environment";

const SupplierModal = () => {

  const { data : currencies } = useFetch('currencies')

  const [supplierName, setSupplierName] = useState("");
  const [description, setDescription] = useState("");
  const [poBox, setPoBox] = useState("");
  const [location, setLocation] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [officePhone, setOfficePhone] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");

  const optionsCurrency = currencies ? currencies.map((currency) => ({
    value: currency.CurrencyID,
    label: currency.CurrencyLabel
  })) : [];

  const handleAddSupplier = async (e) => {
    e.preventDefault();

    const supplierData = {
        CurrencyID: currency.value,
        SupplierName: supplierName,
        SupplierDescription: description,
        SupplierPhone1: officePhone,
        SupplierPhone2: homePhone,
        SupplierMobile: mobilePhone,
        SupplierBox: poBox,
        SupplierLocation: location
    };

    const token = localStorage.getItem('authToken')

    try {
        const response = await axios.post(
          `${api_path}/api/user/addSupplier`, 
            supplierData,
            {
                headers: {
                    'Authorization':token,
                    'Content-Type': 'application/json'
                }
            }
        );

        if(response.data.success){
          alert('Supplier added successful')
          window.location.reload();
        } else {
            console.error('Server responded with an error:', response.data);
        }

    } catch (error) {
        console.error('Error adding supplier:', error.response ? error.response.data : error.message);
    }
};
  


  return (
    <div>
      {/* Add Supplier */}
      <div className="modal fade" id="add-unit">
        <div className="modal-dialog modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Add Supplier</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleAddSupplier}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Supplier Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={supplierName}
                            onChange={(e) => setSupplierName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Description</label>
                          <input
                            type="text"
                            className="form-control"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>P.O.BOX</label>
                          <input
                            type="text"
                            className="form-control"
                            value={poBox}
                            onChange={(e) => setPoBox(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Location</label>
                          <input
                            type="text"
                            className="form-control"
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Currency</label>
                          <Select
                            classNamePrefix="react-select"
                            options={optionsCurrency}
                            value={currency}
                            onChange={setCurrency}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Office Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            value={officePhone}
                            onChange={(e) => setOfficePhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Home Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            value={homePhone}
                            onChange={(e) => setHomePhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-blocks">
                          <label>Mobile Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            value={mobilePhone}
                            onChange={(e) => setMobilePhone(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer-btn">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Supplier */}

      
    </div>
  );
};

export default SupplierModal;
