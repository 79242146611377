/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React,{ useRef, useEffect} from "react";
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
// import { Table } from "antd";
// import { onShowSizeChange } from "./pagination";
import $ from 'jquery';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net-responsive';
import 'datatables.net-buttons';
import 'datatables.net-colreorder';
import 'datatables.net-select';
import 'datatables.net-keytable';

// import 'datatables.net-plugins/dataRender/datetime.mjs';
// import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net';

const ReactDataTable  = ({ 
            id,columns, data, options, childRowFormatter, onRowAction, onRowClick,  
            onEnterPress, onLeftArrowPress, onRightArrowPress, onUpArrowPress, onDownArrowPress   }) => {
  const tableRef = useRef(); 

  useEffect(() =>{
    // console.log(data);
    const table = $(`#${id}`).DataTable ({
      // destroy: true,
      data: data,
      columns: columns,
      keys: true,
      
      // scrollResize: true,
    // scrollY: 400,
    // scrollCollapse: true,
    // paging: false,
      // responsive: true,
      // scrollResize: false,
      // colReorder: true,
      ...options,
      createdRow: (row, rowData) => {
        // Handle rendering custom React content in specific cells
        $(row).find('td').each(function (index) {
          const column = columns[index];
          if (column.renderReactContent) {
            const element = $(this)[0];
            const root = createRoot(element);
            root.render(column.renderReactContent(rowData));
          }
        });
      },
  });

  // Focus on the table after it loads
  tableRef.current.focus();


  $(`#${id}`).on('click', 'button', function () {
    const action = $(this).data('action');
    const rowData = table.row($(this).parents('tr')).data();
    if (onRowAction) onRowAction(action, rowData);
  });

  $(`#${id}`).on('click', 'tr', function () {
    const rowData = table.row(this).data();
    if (onRowClick) onRowClick(rowData);
});

   // Handle keypress events on the table rows
   $(`#${id} tbody`).on('keydown', 'tr', function (e) {
    const row = $(this);
    const rowData = table.row(row).data();
    console.log(table.row(this).data());

    switch (e.key) {
        case 'Enter': {
            if (onEnterPress) {
                onEnterPress(rowData);
            }
            break;
        }
        case 'ArrowUp': {
            if (row.prev().length) {
                row.prev().focus();
            }
            if (onUpArrowPress) {
                onUpArrowPress(rowData);
            }
            break;
        }
        case 'ArrowDown': {
            if (row.next().length) {
                row.next().focus();
            }
            if (onDownArrowPress) {
                onDownArrowPress(rowData);
            }
            break;
        }
        case 'ArrowLeft': {
            if (onLeftArrowPress) {
                onLeftArrowPress(rowData);
            }
            break;
        }
        case 'ArrowRight': {
            if (onRightArrowPress) {
                onRightArrowPress(rowData);
            }
            break;
        }
        default:
            break;
    }
});
 


    // $(tableRef.current).on('keydown', handleKeyDown);

  if (childRowFormatter) {
    $(`#${id}`).on('click', 'td.details-control', function () {
        const tr = $(this).closest('tr');
        const row = table.row(tr);

        if (row.child.isShown()) {
            row.child.hide();
            tr.removeClass('shown');
        } else {
            row.child(childRowFormatter(row.data())).show();
            tr.addClass('shown');
        }
    });
}

  // Set tabindex for each row to make them focusable
  $(`#${id} tbody tr`).attr('tabindex', '0');

  // Add focus to the first row when the table loads
  $(`#${id} tbody tr:first`).focus();


return () => {
  // $(tableRef.current).off('keydown', handleKeyDown);
    table.destroy();
};
  },[id ,data, columns, options, childRowFormatter, onRowAction,  onEnterPress, onLeftArrowPress, onRightArrowPress, onUpArrowPress, onDownArrowPress])

  return (
    <div className="table-responsive product-list">
    <table 
    ref={tableRef} 
    id={id}
    tabIndex={0} // Enable focusing on the table
    className="table  datanew list" 
    style={{ width: '100%' }}>
       <thead>
                <tr>
                {columns.map((column, index) => (
                        <th key={index}>{column.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody />
    </table>
    </div>
    );
};

export default ReactDataTable ;
