import React, { useState, useEffect } from "react";
import useFetch from "../../../useFetch";
// import axios from "axios";
import Addunits from '../inventory/addunits'
import {Modal, Table} from 'antd'
import { X } from 'react-feather'


const AddOrder = () => {

  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);

  const [orderTax, setOrderTax] = useState(0);
  const [discount, setDiscount] = useState(0);

  const { data: searchResults } = useFetch(searchQuery ? `products/${searchQuery}` : null);


  const updateTotals = () => {
    setSelectedProduct((prevProducts) =>
      prevProducts.map(product => {
        const lineTotal = product.quantity * product.cost;
        const totalForeign = lineTotal;
        const totalHome = (lineTotal / 2600).toFixed(2);

        return {
          ...product,
          totalForeign,
          totalHome,
        };
      })
    );
  };

  useEffect(() => {
    updateTotals();
  }, [selectedProduct]);


  const columns = [
    {
      title: 'In Stock',
      dataIndex: 'stock',
      key: 'stock',
    },
    {
      title: 'Part Number',
      dataIndex: 'ProductNumber',
      key: 'ProductNumber',
    },
    {
      title: 'Product Name',
      dataIndex: 'ProductName',
      key: 'ProductName',
    },
    {
      title: 'Product Description',
      dataIndex: 'ProductDescription',
      key: 'Stock',
    },
  ];

  const handleProductSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchQuery) {
        setIsSearchModalVisible(true);
      }
    }
  };

  const addProduct = (newProduct) => {
    setSelectedProduct((prevProducts) => {
      if (prevProducts.length === 0) {
        // If selectedProduct is empty, push new item
        return [newProduct];
      } else {
        // If it has items, append another item
        return [...prevProducts, newProduct];
      }
    });
  };

  const removeProduct = (productId) => {
    setSelectedProduct((prevProducts) => {
      const index = prevProducts.findIndex(product => product.ProductID === productId);
      if (index !== -1) {
        const updatedProducts = [...prevProducts];
        updatedProducts.splice(index, 1);
        return updatedProducts;
      }
      return prevProducts;
    });
  };



  // Function to handle input changes
const handleInputChange = (productId, field, value) => {
  setSelectedProduct((prevProducts) =>
    prevProducts.map(product =>
      product.ProductID === productId
        ? { ...product, [field]: value }
        : product
    )
  );
};

// Calculate total from all rows
const calculateTotalForeign = () => {
  return selectedProduct.reduce((acc, product) => acc + (product.totalForeign || 0), 0);
};

const calculateTotalHome = () => {
  return selectedProduct.reduce((acc, product) => acc + (parseFloat(product.totalHome) || 0), 0).toFixed(2);
};

const totalForeign = calculateTotalForeign();
const totalHome = calculateTotalHome();

  const handleRowSelection = (record) => {
    addProduct(record);
    setIsSearchModalVisible(false);
    setSearchQuery(record.ProductName);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    alert('order created')

    // // Creating an array of purchase data for each selected product
    // const purchaseDataArray = selectedProduct.map(product => ({
    //     productId: product.ProductID,
    //     supplierId: selectedSupplier.value,
    //     purchaseNumber: invoice,
    //     discount: discount,
    //     purchaseQuantity: product.quantity,
    //     productForegnCost: product.cost,
    //     purchaseFlag: "local"
    // }));
    //
    // try {
    //     const token = localStorage.getItem('authToken');
    //     const response = await axios.post(process.env.REACT_APP_API_URL + '/api/user/createPurchase', purchaseDataArray, {
    //         headers: {
    //             'Authorization': token,
    //             'Content-Type': 'application/json'
    //         }
    //     });
    //
    //     if (response.status === 200 || response.status === 201) {
    //         alert("Purchase added successfully");
    //         window.location.reload();
    //     } else {
    //         alert("Failed to add purchase: " + response.statusText);
    //     }
    // } catch (error) {
    //     if (error.response) {
    //         // Server responded with a status other than 200
    //         alert("Error: " + error.response.data.message || "Failed to add purchase");
    //         console.error("Server error:", error.response.data);
    //     } else if (error.request) {
    //         // Request was made but no response received
    //         alert("Error: No response from server");
    //         console.error("Request error:", error.request);
    //     } else {
    //         // Something else caused the error
    //         alert("Error: " + error.message);
    //         console.error("Error:", error.message);
    //     }
    // }
};


  return (
    <div>
      {/* Add Purchase */}
      <div className="modal fade" id="add-orders">
        <div className="modal-dialog purchase modal-dialog-centered stock-adjust-modal">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <div className="content">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>Create Order</h4>
                  </div>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body custom-modal-body">
                  <form onSubmit={handleSubmit}>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="input-blocks">
                          <label>Product Name</label>
                          <input
                            type="text"
                            placeholder="Please type product code and select"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyPress={handleProductSearch}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="modal-body-table">
                          <div className="table-responsive">
                            <table className="table datanew">
                              <thead>
                                <tr>
                                  <th>Part Number</th>
                                  <th>Product</th>
                                  <th>Qty</th>
                                  <th>Purchase Price($)</th>
                                  <th>Line Total (supplier)</th>
                                  <th>Home Cost($)</th>
                                </tr>
                              </thead>
                              <tbody>
                              {selectedProduct.length > 0 ? selectedProduct.map((product) =>
                                 (
                                  <tr key={product.ProductID}>
                                    <td>{product.ProductNumber}</td>
                                    <td>{product.ProductName}</td>
                                    <td><input type="number"
                                               value={product.quantity || 0}
                                               onChange={(e) => handleInputChange(product.ProductID, 'quantity', Number(e.target.value))}
                                          /></td>
                                    <td><input type="number"
                                               value={product.cost || 0}
                                               onChange={(e) => handleInputChange(product.ProductID, 'cost', Number(e.target.value))}
                                        /></td>
                                    <td>{product.totalForeign ? product.totalForeign.toFixed(2) : '0.00'}</td>
                                    <td>{product.totalHome ? product.totalHome : '0.00'}</td>
                                    <td><X onClick={() => removeProduct(product.ProductID)} style={{ cursor: 'pointer', color: 'red' }} /></td>
                                    </tr>
                                )
                              ) : (
                                <tr>
                                  <td colSpan="6" className="text-center">No product selected</td>
                                </tr>
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks">
                            {/*<label>Total Foreign</label>*/}
                            <input type="hidden"  className="form-control" value={totalForeign.toFixed(2)} readOnly />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks">
                            <label>Sub Total</label>
                            <input type="number" value={orderTax} onChange={(e) => setOrderTax(Number(e.target.value))} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks">
                            <label>vat</label>
                            <input type="number" value={discount} onChange={(e) => setDiscount(Number(e.target.value))} />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <div className="input-blocks">
                            <label>Total</label>
                            <input type="number" className="form-control" value={totalHome} readOnly />
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Purchase */}

      {/* Search Results Modal */}
      <Modal
        title="Search Results"
        visible={isSearchModalVisible}
        onCancel={() => setIsSearchModalVisible(false)}
        footer={null}
        width={800}
        zIndex={1500}
      >
        <Table
          columns={columns}
          dataSource={searchResults}
          rowKey="ProductID"
          onRow={(record) => ({
            onClick: () => handleRowSelection(record),
          })}
        />
      </Modal>

      <Addunits />

    </div>
  );
};

export default AddOrder;