import React, {
  createContext,
  useContext,
  useRef,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";

import io from "socket.io-client";
import { api_path } from "../environment";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [isConnected, setConnected] = useState(false);

  const socketUrl = api_path;

  const socket = useRef(null);

  const handleOnMessage = (message) => {
    console.log(message);
    // store.dispatch here
  };

  useEffect(() => {
    if (!isConnected) {
      socket.current = io(socketUrl, {
        transports: ["websocket"],
        query: {
          token: localStorage.getItem("authToken"),
        },
      });

      socket.current.on("connect", () => {
        console.info(`Successfully connected to socket at ${socketUrl}`);
        setConnected(true);
      });

      socket.current.on("disconnect", () => {
        console.info(`Successfully disconnected`);
        setConnected(false);
      });

      socket.current.on("error", (err) => {
        console.log("Socket Error:", err.message);
      });

      socket.current.on("message", handleOnMessage);
    }

    return () => {
      if (socket.current && socket.current.connected) {
        socket.current.disconnect();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={socket.current}>
      {children}
    </SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useSocket = () => useContext(SocketContext);
