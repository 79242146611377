import { useState, useEffect } from "react";
import axios from "axios";
import { api_path } from "./environment";

const useFetch = (endpoint) => {
  const [data, setData] = useState(null);
  const [per, setPer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
       const token = localStorage.getItem("authToken");
        if (!token) {
          throw new Error("No token found");
        }

        if(endpoint == null) return;

         const response = await axios.get(
          api_path + `/api/user/${endpoint}`,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        setData(response.data.data);
        setPer(response.data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchUser();
  }, [endpoint]);

  return { data, loading, error, per };
};

export default useFetch;
