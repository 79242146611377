import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import EditLowStock from "../../modals/inventory/editlowstock";
import AddOrder from "../../modals/purchases/addOrder";
import useFetch from "../../../useFetch";
import { Link } from "react-router-dom";
import { Table, Modal, InputNumber } from "antd";
import Select from 'react-select'
import PropTypes from 'prop-types';

import AvailabilityGroup from "../inventory/availabilitygroup";
import { Eye } from "feather-icons-react/build/IconComponents";
import { api_path } from "../../../environment";
import axios from "axios";
import { Save, ShoppingCart, XOctagon } from "react-feather";

const EditableCell = ({
    editing,
    // dataIndex,
    // title,
    inputType,
    // record,
    // index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === 'number' ? (
        <InputNumber
            style={{ width: '100%' }}
            min={0}
            precision={2}
        />
    ) : (
        <input style={{ width: '100%' }} />
    );

    return (
        <td {...restProps}>
            {editing ? (
                <div>
                    {inputNode}
                </div>
            ) : (
                children
            )}
        </td>
    );
};

EditableCell.propTypes = {
    editing: PropTypes.bool.isRequired,
    dataIndex: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    inputType: PropTypes.oneOf(['text', 'number']).isRequired,
    record: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired
};


const NewInquiryWindow = () => {
    const { id } = useParams()

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const windowStatus = queryParams.get('status');

    const isViewOnly = windowStatus == 'viewInquiry';

    const [selectedDetail, setSelectedDetail] = useState(null);
    const [chassis, setChassis] = useState('')
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchModalVisible, setIsSearchModalVisible] = useState(false);
    const [isOtherDetailsModalVisible, setIsOtherDetailsModalVisible] = useState(false);
    const [ProductID, setProductID] = useState("");
    const [ProductIDs, setProductIDs] = useState([]);
    const [returnedData, setReturnedData] = useState(null)

    const { data: searchResults } = useFetch(searchQuery ? `products/${searchQuery}` : null);
    const { data: tempProducts } = useFetch(returnedData ? `tempProductForInquiry/${returnedData?.productID}` : null)
    const { data: customerInquiries } = useFetch(isViewOnly ? `customerInquiries/${id}` : null)
    console.log(customerInquiries)
    const [date, setDate] = useState(new Date().toISOString().split('T')[0])
    const [mobile1, setMobile1] = useState('')
    const [mobile2, setMobile2] = useState('')
    const [mobile3, setMobile3] = useState('')

    const [desc, setDesc] = useState('')
    const [detailID, setDetailID] = useState('')

    const [quantity, setQuantity] = useState('')
    const [price, setPrice] = useState('')
    const [comment, setComment] = useState('')

    const [editingKey, setEditingKey] = useState('');

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (tempProducts) {
            const combinedData = [{
                ...tempProducts,
                quantity: quantity,
                priceVAT: price,
                vat: 0.18 * price,
                price: price - (0.18 * price),
                comment: comment,
                lineTotal: quantity && price ? (quantity * price).toFixed(2) : '',
                stockQty: 'N/A',
            }];

            setTableData(combinedData);
        }
    }, [tempProducts, quantity, price, comment]);

    useEffect(() => {
        if (customerInquiries && Array.isArray(customerInquiries) && customerInquiries.length > 0) {
            setDesc(customerInquiries[0].InquiryDescription);
        }
    }, [customerInquiries]);


    // i have to fetch for returned product where ID is returnedData.productId
    const { data: customer, loading, error } = useFetch('customer/' + id)
    const { data: customerDetails } = useFetch('customerDetails/' + id);

    useEffect(() => {
        if (customer) {
            setMobile1(customer.CustomerMobile)
            setMobile2(customer.CustomerMobile1)
            setMobile3(customer.CustomerMobile2)
        }
    }, [customer])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>
    }

    const allDetails = customerDetails ? customerDetails.map((detail) => ({
        value: detail.id,
        label: detail.DetailDescription + detail.CarChassisNumber
    })) : [];

    const handleDetailChange = (selectedOption) => {
        const selectedDetail = customerDetails.find(detail => detail.id === selectedOption.value);

        if (selectedDetail) {
            setChassis(selectedDetail.CarChassisNumber);
            setDetailID(selectedDetail.id)
        }

        setSelectedDetail(selectedOption);
    };

    const handleProductSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearch(e);
        }
    };
    const handleSearch = (e) => {
        e.preventDefault()
        if (searchQuery) {
            setIsSearchModalVisible(true);
        }
    };

    const handleRowSelection = (record) => {
        setIsSearchModalVisible(false);
        getAvailabilityGroup(record.ProductID);
    };

    const getAvailabilityGroup = (prodID) => {
        setProductID(prodID);
    };



    const handleReturnData = (productId, data) => {
        if (data && typeof data === "object" && "StoreID" in data) {
            if (ProductIDs.includes(productId)) {
                alert("Product ID already exists:", productId);
            } else {
                setReturnedData({
                    productID: productId,
                    StoreID: data.StoreID,
                });
                setProductIDs((prev) => [...prev, productId]);
                setIsOtherDetailsModalVisible(true);
            }
        } else {
            console.error("Invalid data or StoreID not found in data:", data);
        }
    };


    const handleOtherDetailsSubmit = () => {
        setIsOtherDetailsModalVisible(false);
    };

    const searchResultsColumns = [
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record) => (
                <div className="action-table-data">
                    <div className="edit-delete-action">
                        <div className="input-block add-lists"></div>
                        <Link
                            onClick={() => getAvailabilityGroup(record.ProductID)}
                            data-bs-toggle="modal"
                            data-bs-target="#availability-group"
                            className="me-2 p-2" to={''}
                        >
                            <Eye className="feather-view" />
                        </Link>

                    </div>
                </div>
            ),
        },
        {
            title: 'In Stock',
            dataIndex: 'stock',
            key: 'stock',
        },
        {
            title: 'Part Number',
            dataIndex: 'ProductNumber',
            key: 'ProductNumber',
        },
        {
            title: 'Product Name',
            dataIndex: 'ProductName',
            key: 'ProductName',
        },
        {
            title: 'Product Description',
            dataIndex: 'ProductDescription',
            key: 'Stock',
        },
    ];

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = (record) => {
        const newData = [...tableData];
        const index = newData.findIndex((item) => record.key === item.key);

        if (index > -1) {
            const item = newData[index];
            const editedItem = {
                ...item,
                quantity: item.quantity,
                priceVAT: item.priceVAT,
                comment: item.comment,
                lineTotal: (item.quantity * item.priceVAT).toFixed(2),
                vat: (0.18 * item.priceVAT).toFixed(2),
                price: (item.priceVAT - (0.18 * item.priceVAT)).toFixed(2)
            };

            newData.splice(index, 1, editedItem);
            setTableData(newData);
            setEditingKey('');
        }
    };

    const columns = [
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <a onClick={() => save(record)} style={{ marginRight: 8 }}>
                            Save
                        </a>
                        <a onClick={cancel}>Cancel</a>
                    </span>
                ) : (
                    <div className="action-table-data">
                        <div className="edit-delete-action">
                            <Link
                                className="me-2 p-2"
                                onClick={() => edit(record)}
                                to="#"
                            >
                                <i data-feather="edit" className="feather-edit"></i>
                            </Link>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Part Number",
            dataIndex: "ProductNumber",
            sorter: (a, b) => a.ProductNumber.length - b.ProductNumber.length,
        },
        {
            title: "Description",
            dataIndex: "ProductDescription",
            sorter: (a, b) => a.ProductDescription.length - b.ProductDescription.length,
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            editable: true,
            sorter: (a, b) => (a.quantity || 0) - (b.quantity || 0),
        },
        {
            title: "Price(VAT)",
            dataIndex: "priceVAT",
            editable: true,
            sorter: (a, b) => (a.priceVAT || 0) - (b.priceVAT || 0),
        },
        {
            title: "Price",
            dataIndex: "price",
            sorter: (a, b) => (a.price || 0) - (b.price || 0),
        },
        {
            title: "VAT",
            dataIndex: "vat",
            sorter: (a, b) => (a.vat || 0) - (b.vat || 0),
        },
        {
            title: "Line Total",
            dataIndex: "lineTotal",
            sorter: (a, b) => (a.lineTotal || 0) - (b.lineTotal || 0),
        },
        {
            title: "Stock Qty",
            dataIndex: "stockQty",
        },
        {
            title: "Shelf",
            dataIndex: "ProductShelf",
        },
        {
            title: "Comment",
            dataIndex: "comment",
            editable: true,
        }
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'quantity' || col.dataIndex === 'priceVAT' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });


    const handleSaveInquiry = async () => {
        const inquiryDetails = {
            productID: returnedData?.productID,
            storeID: returnedData?.StoreID,
            inquiryDate: date,
            inquiryDescription: desc,
            inquiryStatus: 'NEW',
            inquiryPrice: price,
            inquiryQuantity: quantity,
            inquiryComment: comment,
            detailID: detailID,

            customerID: id,
            mobile1: mobile1,
            mobile2: mobile2,
            mobile3: mobile3,
        }
        //    console.log(inquiryDetails)

        try {
            const token = localStorage.getItem('authToken');

            const response = await axios.post(api_path + '/api/user/createInquiry', inquiryDetails, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            console.log("Full response:", response);

            if (response.status === 200) {
                alert(response.data.message);
                window.location.reload();
            } else {
                alert(response.data.message + response.statusText);
            }

        } catch (error) {
            if (error.response) {
                alert("Error: " + error.response.data.message || "Failed to add purchase");
                console.error("Server error:", error.response.data);
            } else if (error.request) {
                alert("Error: No response from server");
                console.error("Request error:", error.request);
            } else {
                alert("Error: " + error.message);
                console.error("Error:", error.message);
            }
        }
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="content">

                    <div className="page-header">
                        <div className="add-item d-flex">
                            <div className="page-title">
                                <h4>{isViewOnly ? 'View' : 'New'} Inquiry</h4>
                            </div>
                        </div>
                        {!isViewOnly && (
                            <ul className="table-top-head">
                                <li>
                                    <div className="page-btn">
                                        <Link to="#"
                                            onClick={handleSaveInquiry}
                                            className="btn btn-success">
                                            <Save className="me-1" />
                                            Save
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="page-btn">
                                        <Link to="#" className="btn btn-secondary">
                                            <XOctagon className="me-1" />
                                            Cancel
                                        </Link>
                                    </div>
                                </li>
                                <li>
                                    <div className="page-btn">
                                        <Link to="#" className="btn btn-primary">
                                            <ShoppingCart className="me-1" />
                                            Sale
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        )}
                    </div>

                    <div className="card">
                        <div className="card-body d-flex" style={{ gap: '20px' }}>
                            {/* Left Section */}
                            <div className="localization-info w-50">
                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <div className="setting-info">
                                            <p>Customer</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="localization-select">
                                            <input
                                                type="text"
                                                placeholder="Customer"
                                                className="form-control form-control-sm formsearch"
                                                value={customer.CustomerFullName}
                                                disabled

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <div className="setting-info">
                                            <p>Details:</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="localization-select">

                                            <Select
                                                placeholder="Choose"
                                                options={allDetails}
                                                classNamePrefix="react-select"
                                                value={selectedDetail}
                                                onChange={handleDetailChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <div className="setting-info">
                                            <p>Chassis</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="localization-select">
                                            <input
                                                type="text"
                                                placeholder="Chassis"
                                                className="form-control form-control-sm formsearch"
                                                value={chassis}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <div className="setting-info">
                                            <p>Desc</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="localization-select">
                                            <input
                                                type="text"
                                                placeholder="Description"
                                                className="form-control form-control-sm formsearch"
                                                value={desc}
                                                onChange={(e) => setDesc(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <div className="setting-info">
                                            <p>Search</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="localization-select">
                                            <div className="search-set">
                                                <div className="search-input">
                                                    <input
                                                        type="text"
                                                        autoFocus
                                                        placeholder="Search"
                                                        className="form-control form-control-sm formsearch"
                                                        value={searchQuery}
                                                        onKeyDown={handleProductSearch}
                                                        onChange={(e) => setSearchQuery(e.target.value)}
                                                    />
                                                    <Link onClick={(e) => handleSearch(e)} className="btn btn-searchset">
                                                        <i data-feather="search" className="feather-search" />
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Right Section */}
                            <div className="localization-info w-50">
                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <div className="setting-info">
                                            <p>Date</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="localization-select">
                                            <input
                                                type="date"
                                                disabled
                                                className="form-control form-control-sm formsearch"
                                                value={date}
                                                onChange={(e) => setDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <div className="setting-info">
                                            <p>Mobile 1</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="localization-select">
                                            <input
                                                type="text"
                                                placeholder=""
                                                className="form-control form-control-sm formsearch"
                                                value={mobile1}
                                                onChange={(e) => setMobile1(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <div className="setting-info">
                                            <p>Mobile 2</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="localization-select">
                                            <input
                                                type="text"
                                                placeholder=""
                                                className="form-control form-control-sm formsearch"
                                                value={mobile2}
                                                onChange={(e) => setMobile2(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-sm-3">
                                        <div className="setting-info">
                                            <p>Mobile 3</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="localization-select">
                                            <input
                                                type="text"
                                                placeholder=""
                                                className="form-control form-control-sm formsearch"
                                                value={mobile3}
                                                onChange={(e) => setMobile3(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="card table-list-card">
                        <div className="card-body">
                            {/* <div className="table-top">
                                <div className="search-set">
                                    <div className="search-input">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="form-control form-control-sm formsearch"
                                            // value={searchQuery}
                                            // onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                        <Link to className="btn btn-searchset">
                                            <i
                                                data-feather="search"
                                                className="feather-search"
                                            />
                                        </Link>
                                    </div>
                                </div>

                            </div> */}
                            <div className="table-responsive">
                                <Table
                                    components={{
                                        body: {
                                            cell: EditableCell,
                                        },
                                    }}
                                    columns={mergedColumns}
                                    dataSource={isViewOnly ? customerInquiries : tableData}
                                    onRow={(record) => ({
                                        onClick: () => {
                                            const editable = isEditing(record);
                                            if (!editable) {
                                                edit(record);
                                            }
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end">
                            This is footer
                        </div>
                    </div>
                </div>

            </div>

            <EditLowStock />
            <AddOrder />

            {/* Search Results Modal */}
            <Modal
                title="Search Results"
                visible={isSearchModalVisible}
                onCancel={() => setIsSearchModalVisible(false)}
                footer={null}
                width={800}
            >
                <Table
                    columns={searchResultsColumns}
                    dataSource={searchResults}
                    rowKey="ProductID"
                    onRow={(record) => ({
                        onClick: () => handleRowSelection(record),
                    })}
                />
            </Modal>
            {/* search result */}

            <AvailabilityGroup
                id="availability-group"
                productId={ProductID}
                returnData={handleReturnData}
            // onItemSelect={handleItemSelect}
            // ProductID={ProductID}
            // show={showModal}
            // onHide={() => setShowModal(false)}
            // ProductID={selectedProductId}
            // onItemSelect={handleItemSelect}
            />

            {/* other details */}
            <Modal
                title="Other Details"
                visible={isOtherDetailsModalVisible}
                onCancel={() => setIsOtherDetailsModalVisible(false)}
                footer={null}
                width={400}
            >
                <div className="">
                    <div className="mb-3">
                        <label className="form-label">Quantity</label>
                        <input type="number" className="form-control" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Price</label>
                        <input type="number" className="form-control" value={price} onChange={(e) => setPrice(e.target.value)} required />
                    </div>

                    <div className="mb-3">
                        <label className="form-label">Comment</label>
                        <input type="text" className="form-control" value={comment} onChange={(e) => setComment(e.target.value)} />
                    </div>
                    <button className="btn btn-primary" onClick={handleOtherDetailsSubmit}>
                        Submit
                    </button>
                </div>
            </Modal>
            {/* other details */}
        </div>
    );
};

export default NewInquiryWindow;
