/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import ToastAlert from '../../ui-tools/ToastAlert'
// import Select from 'react-select'
// import axios from 'axios';
import ReactDataTable from '../../pagination/reactdatatables';
import useFetch from '../../../useFetch';

const AvailabilityGroup = ({ id, productId, returnData }) => {

    const [ProductID, setProductID] = useState([]);
    const [fetchStockString, setFetchStockString] = useState("");
    const [fetchGroupString, setFetchGroupString] = useState("");
    // const [selectedItem, setSelectedItem] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(false);
    const { data: availableStock } = useFetch(fetchStockString);
    const { data: groupProductList } = useFetch(fetchGroupString);


    // const handleStockRowClick = (rowData) => {
    //     console.log(rowData);
    //     returnData(
    //         productId,
    //         rowData
    //     );

    //     setSelectedItem(rowData);
    //     // const modalElement = document.getElementById(id);
    //     // const bootstrapModal = window.bootstrap.Modal.getInstance(modalElement);
    //     // bootstrapModal.hide();
    // };

    const handleGroupRowClick = (rowData) => {
        if (typeof (rowData) !== 'undefined' && rowData != null) {
            console.log(rowData.ProductID);
            setProductID(rowData.ProductID);
            setSelectedGroup(false);
        }
    };

    // const handleKeyPress = (rowData) => {

    //         console.log("Selected ", rowData, " data:" );
    //         returnData(
    //                     productId,
    //                     rowData
    //                 );
    //         // onItemSelect(selectedItem);          
    //     };


    useEffect(() => {
        if (productId) {
            //   console.log("Yes", productId);
            //   window.addEventListener('keydown', handleKeyPress);
            //   let productID= data.productID;
            // console.log(data.productID);
            setProductID(productId);
            //     setProductID(productID.productId);
        } else {

            //   window.removeEventListener('keydown', handleKeyPress);
            // console.log( "No" ,JSON.stringify(productId));

        }

    }, [id, productId]);

    useEffect(() => {
        if (ProductID) {
            console.log(ProductID);
            setFetchStockString(`availstock/${ProductID}`);

        }
        setSelectedGroup(false);

    }, [ProductID]);

    const getGroupItems = async () => {
        setFetchGroupString(`groupproducts/${ProductID}`);
        // getGroupProducts
        // e.preventDefault();
        // console.log(saleListData[0]);
        setSelectedGroup(true);


    }
    const getStockItems = async (e) => {
        e.preventDefault();

        setSelectedGroup(false);


    }

    const handleEnterPress = (rowData) => {
        console.log('Enter pressed for product:', rowData);
        returnData(
            productId,
            rowData
        );
        const modalElement = document.getElementById('closeAvailGroup');

        // Check if the element has the attribute data-bs-dismiss="modal"
        if (modalElement && modalElement.getAttribute('data-bs-dismiss') === 'modal') {
            // You have successfully grabbed the element with the attribute data-bs-dismiss="modal"
            console.log(modalElement);
            modalElement.click();
        } else {
            console.log('Element with data-bs-dismiss="modal" not found');
        }
        // Add logic to handle the Enter key press event
    };

    const handleLeftArrowPress = (rowData) => {
        console.log('Left arrow pressed for product:', rowData);
        // Add logic to handle the Left arrow key press event
    };

    const handleRightArrowPress = (rowData) => {
        console.log('Right arrow pressed for product:', rowData);
        getGroupItems();
        setSelectedGroup(true);
    };

    const handleUpArrowPress = (rowData) => {
        console.log('Up arrow pressed for product:', rowData);
        // Add logic to handle the Up arrow key press event
    };

    const handleDownArrowPress = (rowData) => {
        console.log('Down arrow pressed for product:', rowData);
        // Add logic to handle the Down arrow key press event
    };

    const columns = [
        { title: "Store ID ", data: "StoreID", visible: false },
        { title: "Store Name ", data: "StoreName" },
        { title: "Shelf ", data: "ProductShelf" },
        { title: "Stock ", data: "stock" },

        { className: "details-control", orderable: false, data: null, defaultContent: "" },
    ];
    const groupcolumns = [
        { title: "Stock ", data: "stock" },
        { title: "ProductID ", data: "ProductID", visible: false },
        { title: "Part Number ", data: "ProductNumber" },
        { title: "Description ", data: "ProductName" },
        { title: "Description 2 ", data: "ProductDescription" },


        { className: "details-control", orderable: false, data: null, defaultContent: "" },
    ];

    return (
        <div>
            {/* Import Product */}
            <div className="modal fade" id={id}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="page-wrapper-new p-0">
                            <div className="content">
                                <div className="modal-header border-0 custom-modal-header">
                                    <div className="page-title">
                                        <h4>Availability and Group</h4>
                                    </div>
                                    <button
                                        id='closeAvailGroup'
                                        type="button"
                                        className="close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body custom-modal-body">
                                    <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded nav-justified mb-3">
                                        <li className="nav-item">
                                            <Link
                                                className={selectedGroup ? "nav-link " : "nav-link active"}
                                                data-bs-toggle="tab"
                                                role="tab"
                                                onClick={(e) => getStockItems(e)}
                                                to="#StoreAvailability"
                                                aria-selected={!selectedGroup}
                                            >
                                                Availabilty
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link
                                                className={selectedGroup ? "nav-link active" : "nav-link"}
                                                data-bs-toggle="tab"
                                                role="tab"
                                                onClick={() => getGroupItems()}
                                                to="#GroupProducts"
                                                aria-selected={selectedGroup}
                                            >
                                                Group
                                            </Link>
                                        </li>
                                    </ul>

                                    <div className="tab-content">
                                        <div
                                            className={selectedGroup ? "tab-pane text-muted " : "tab-pane text-muted active"}
                                            id="StoreAvailability"
                                            role="tabpanel"
                                        >
                                            {/* Entry the List here */}
                                            <div className="table-responsive">
                                                {availableStock && <ReactDataTable
                                                    id="stock-list-table"
                                                    data={availableStock}
                                                    // onRowClick={handleStockRowClick}
                                                    options={{
                                                        colResize: false,
                                                        realtime: true,
                                                        paging: true,
                                                        // lengthChange: false,
                                                        pageResize: false,
                                                        searching: true,
                                                        // colReorder: true, 
                                                        select: true,
                                                        info: false,
                                                        order: [[1, 'desc']]
                                                    }}
                                                    columns={columns}
                                                    onEnterPress={handleEnterPress}  // Pass the enter key handler function
                                                    onRowClick={handleEnterPress}  // Pass the enter key handler function
                                                    onLeftArrowPress={handleLeftArrowPress}  // Pass the left arrow key handler function
                                                    onRightArrowPress={handleRightArrowPress}  // Pass the right arrow key handler function
                                                    onUpArrowPress={handleUpArrowPress}  // Pass the up arrow key handler function
                                                    onDownArrowPress={handleDownArrowPress}  // Pass the down arrow key handler function
                                                    // onRowAction={handleRowAction}
                                                    tabindex="0"

                                                />}
                                            </div>

                                        </div>
                                        <div
                                            className={selectedGroup ? "tab-pane text-muted active" : "tab-pane text-muted"}
                                            id="GroupProducts"
                                            role="tabpanel"
                                        >
                                            {/* <b>List of groups here</b> */}
                                            <div className="table-responsive">
                                                {groupProductList && <ReactDataTable
                                                    id="group-product-list-table"
                                                    data={groupProductList}
                                                    onRowClick={handleGroupRowClick}
                                                    options={{
                                                        // colResize: true,
                                                        // realtime: false,
                                                        paging: false,
                                                        // lengthChange: false,
                                                        pageResize: true,
                                                        // searching: true, 
                                                        // colReorder: true 
                                                        // select: true,
                                                        // searching: false, paging: false, 
                                                        info: true,
                                                        order: [[0, 'desc']]
                                                    }}
                                                    columns={groupcolumns}
                                                // onRowAction={handleRowAction}
                                                />}
                                            </div>
                                        </div>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Import Product */}
        </div>
    )
}

export default AvailabilityGroup
