import React, { useEffect, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { pagesRoute, posRoutes, publicRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import ThemeSettings from "../InitialPage/themeSettings";
import Loader from "../feature-module/loader/loader";
import { useSocket } from "../component/socketContext";

const AllRoutes = () => {  
  const data = useSelector((state) => state.toggle_header);

  const HeaderLayout = () => {
    const socket = useSocket(); 

    useEffect(() => {
      if (socket && socket.connected) {          
        socket.emit("message", { message: "Client connected" });

        socket.on("response", (data) => {
          console.log("Received response in HeaderLayout:", data);
        });

        return () => {
          socket.off("response");
        };
      }
    }, [socket]);

    return (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
    <Loader />
    <Header />
    <Sidebar />
    <Outlet />
    <ThemeSettings />
  </div>
  );
};

  const Pospages = () => {
    const socket = useSocket();

    useEffect(() => {
      if (socket && socket.connected) {
        socket.emit("message", { message: "Client connected" });

        socket.on("response", (data) => {
          console.log("Received response in Pospages:", data);
        });

        return () => {
          socket.off("response");
        };
      }
    }, [socket]);

    return (
    <div>
      <Header />
      <Outlet />
      <Loader />
      <ThemeSettings />      
    </div>
  );
  };

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      <Loader />
    </div>
  );

  return (
    <div>
      <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/pos" element={<Pospages />}>
          {posRoutes.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
        <Route path={"/"} element={<HeaderLayout />}>
          {publicRoutes.map((route, id) => (            
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
        <Route path={"/"} element={<Authpages />}>
          {pagesRoute.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
      </Routes>
      </Suspense>
    </div>
  );
};

export default AllRoutes;
